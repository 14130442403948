import {
  formatDistanceToNow,
  formatDistanceToNowStrict,
  parseISO
} from 'date-fns'

import locale from 'date-fns/locale/en-US/index.js'

export const msPerSecond = 1000
export const msPerMinute = msPerSecond * 60
export const msPerHour = msPerMinute * 60
export const msPerDay = msPerHour * 24
export const msPerWeek = msPerDay * 7
export const msPerMonth = msPerDay * 30
export const msPerYear = msPerDay * 365

/*
 * @doctests
 *
 * ```js
 * t.is(secondsForHumans(60), '1.0m')
 * t.is(secondsForHumans(66), '1.1m')
 * t.is(secondsForHumans(30), '30s')
 * t.is(secondsForHumans(37000), '10.3h')
 * ```
 */
export function secondsForHumans(seconds) {
  if (seconds > 86400) {
    return Math.round(seconds / 86400) + 'd'
  } else if (seconds >= 3600) {
    return (seconds / 3600).toFixed(1) + 'h'
  } else if (seconds >= 60) {
    return (seconds / 60).toFixed(1) + 'm'
  } else {
    return Math.round(seconds) + 's'
  }
}

/*
 * @doctests
 *
 * ```js
 * t.is(stringToIntSeconds('1m'), 60)
 * t.is(stringToIntSeconds('33s'), 33)
 * t.is(stringToIntSeconds('33'), 33)
 * ```
 */
const numTimeRegex = /(\d+\.?\d*) *([smh])/
const numRegex = /(\d+\.?\d*)/
export function stringToIntSeconds(str) {
  const match = str.match(numTimeRegex) || str.match(numRegex)
  if (match == null) return 0
  const [, d, t = 's'] = match
  const digit = Math.round(parseFloat(d))

  switch (t) {
    case 's':
      return digit
    case 'm':
      return digit * 60
    case 'h':
      return digit * 3600
    default:
      console.trace('stringToIntSeconds bug!')
  }
}
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/*
 * @doctests
 *
 * ```js
 * t.is(formatDate('2024-04-22T18:51:28.513Z'), 'Apr 22, 2024')
 * ```
 */
export function formatDate(date, args = {}) {
  return getDate(date).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    ...args
  })
}

//export function formatTime(date) {
//return getDate(date).toLocaleTimeString()
//}

function getDate(date) {
  if (date instanceof Date) {
    return date
  } else {
    return new Date(date)
  }
}

export function getUTCDaysInMonth(utcDate) {
  const date = getDate(utcDate)
  const month = date.getUTCMonth()
  const year = date.getUTCFullYear()
  return new Date(Date.UTC(year, month + 1, 0)).getUTCDate()
}

////////////////////////////////////////////////////////////////////////////////
const formatDistanceAbbrev = {
  lessThanXSeconds: '{{x}} s',
  xSeconds: '{{x}} s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{x}} min',
  xMinutes: '{{x}} min',
  aboutXHours: '{{x}} hr',
  xHours: '{{x}} hr',
  xDays: '{{x}} d',
  aboutXWeeks: '{{x}} w',
  xWeeks: '{{x}} w',
  aboutXMonths: '{{x}} mo',
  xMonths: '{{x}} mo',
  aboutXYears: '{{x}} y',
  xYears: '{{x}} y',
  overXYears: '{{x}} y',
  almostXYears: '{{x}} y'
}

function formatDistance(token, count) {
  return formatDistanceAbbrev[token].replace('{{x}}', count)
}

/*
 * @doctests
 *
 * ```js
 * t.is(fromNow(new Date()), 'less than a minute')
 * ```
 */
export function fromNow(t) {
  return formatDistanceToNow(t)
}

/*
 * @doctests
 *
 * ```js
 * t.is(fromNowShort(new Date()), '0 s')
 * ```
 */
export function fromNowShort(t) {
  return formatDistanceToNowStrict(t, {
    addSuffix: true,
    locale: {
      ...locale,
      formatDistance
    }
  })
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(enrichPosixTime({date: "2023-08-23T16:04:01Z"}, 'date'), { date: "2023-08-23T16:04:01Z", _date: 1692806641000 })
 * t.deepEqual(enrichPosixTime({date: "2023-08-23T16:04:01Z"}, 'bad-key'), { date: "2023-08-23T16:04:01Z" })
 * ```
 */
export function enrichPosixTime(obj, key) {
  if (key in obj) {
    return { ...obj, ['_' + key]: +new Date(obj[key]) }
  }
  return obj
}

export { parseISO }
